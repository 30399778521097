import smoothscroll from "smoothscroll-polyfill"

import { setupTracking, runPageTracking } from "./src/helpers/tracking"

// Initial setup on page load
export const onClientEntry = () => {
  smoothscroll.polyfill()

  setupTracking()
}

// Track page views on every route change
export const onRouteUpdate = ({ location }) => {
  let locationStr = ""

  if (location) {
    locationStr = `${location.pathname}${location.search}${location.hash}`
  }
  runPageTracking(locationStr)
}
