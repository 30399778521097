import sanitizeHtml from "sanitize-html"

export const sanitizeInput = (input) => {
  const sanitizeString = (str) =>
    sanitizeHtml(str, {
      allowedTags: [],
      allowedAttributes: {},
    })

  return Array.isArray(input)
    ? input.map((element) => sanitizeString(element))
    : sanitizeString(input)
}

export const isUrlPattern = (url) => {
  const pattern = new RegExp(
    /(\b(https?|ftp|file):\/\/|www\.|[a-zA-Z0-9-]+\.[a-zA-Z]{2,})\S*/gi
  )
  return pattern.test(url)
}
