import Cookies from "universal-cookie"
import PiwikPro, { UserManagement } from "@piwikpro/react-piwik-pro"
import { getQueryParams } from "react-use-query-param-string"

import { daysFromNow, whenAvailable, whenCondition } from "./"

const cookies = new Cookies()
const debug = process.env.GATSBY_DEBUG_TRACKING === "true"

// Consent Manager will be excluded on these pages as we are otherwise blocking the legal text that a user must be able to read.
export const excludeConsentPaths =
  /404|customer-information|imprint|informaciones-clientes|informations-clients|informazioni-clienti|privacy-policy|terms-of-use/

export const cookieForever = {
  expires: new Date("2099-12-31T23:59:59.000Z"),
  path: "/",
}

export const cookie90days = {
  expires: daysFromNow(90),
  path: "/",
}

export const setEnvironment = () => {
  window.dataLayer = window.dataLayer || [] // make sure we don't overwrite the existing array
  window.dataLayer.push({
    environment: process.env.GATSBY_ENVIRONMENT_NAME,
  })

  function gtag() {
    window.dataLayer.push(arguments)
  }

  gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
    wait_for_update: 1500,
  })
}

// Save Google AdWords GCLID to a cookie
export const saveTrackingIds = () => {
  const { gclid, gclsrc } = getQueryParams()

  if (gclid && (!gclsrc || gclsrc.indexOf("aw") !== -1)) {
    cookies.set("gclid", gclid, cookie90days)
    debug &&
      console.log(`Google AdWords GCLID saved to cookie with value "${gclid}"`)
  }
}

// Save UTM parameters to a cookie
export const saveUtmParams = () => {
  const urlParams = getQueryParams()
  const utmParams = {}

  // Look only for utm_ params
  Object.entries(urlParams).forEach(([key, value]) => {
    if (key.startsWith("utm_") && value) {
      utmParams[key.substring(4)] = value
    }
  })

  // Save them to a cookie
  if (Object.keys(utmParams).length > 0) {
    cookies.set("solaris_utm", JSON.stringify(utmParams), cookie90days)
    debug &&
      console.log(
        `UTM params saved to cookie with value "${JSON.stringify(utmParams)}"`
      )
  }
}

// Gets the Piwik Consent Manager status for a given service
export const getConsentStatus = (service, silent) => {
  return new Promise((resolve, reject) => {
    debug && !silent && console.log(`Getting consent for "${service}"`)

    whenAvailable("ppms", () => {
      window.ppms?.cm?.api(
        "getComplianceSettings",
        (response) => {
          let status
          switch (response?.consents[service]?.status) {
            case -1:
              status = undefined
              break
            case 1:
              status = true
              break
            default:
              status = false
              break
          }
          debug &&
            !silent &&
            console.log(`Consent for "${service}" is ${status}`)
          resolve(status)
        },
        () => reject(new Error("Consent object not available."))
      )
    })
  })
}

// Checks if the user has previously interacted with the Consent Manager
export const getInteractionStatus = async () => {
  const status = await getConsentStatus("analytics", true)
  return status === undefined ? false : true
}

// Enables the Piwik Consent Manager
const enableConsentManager = async () => {
  const interacted = await getInteractionStatus()
  // debug && console.log(`Interaction status is ${interacted}`)
  if (!interacted) {
    whenAvailable("ppms", () => {
      debug && console.log("Consent Manager enabled")
      const cm = document.querySelector("div[id^=ppms_cm_consent_popup_]")
      if (cm) {
        cm.style.display = "block"
      } else {
        // Add a slight delay because it's not always reliable
        setTimeout(() => {
          window.ppms.cm.api("openConsentForm")
        }, 200)
      }
    })
  }
}

// Disables the Piwik Consent Manager
const disableConsentManager = async () => {
  whenAvailable("ppms", async () => {
    whenCondition(
      () => document.querySelector("div[id^=ppms_cm_consent_popup_]") !== null,
      () => {
        debug && console.log("Consent Manager disabled")
        const cm = document.querySelector("div[id^=ppms_cm_consent_popup_]")
        cm.style.display = "none"
      },
      () => false,
      10
    )
  })
}

// Gets the Piwik Visitor ID
export const getVisitorId = async () => {
  try {
    const visitorId = await UserManagement.getVisitorId()
    return visitorId
  } catch (error) {
    console.log(error)
  }
}

// Tracks a page view. Called manually from runPageTracking() below.
export const trackPageView = (title, locationStr) => {
  window.dataLayer = window.dataLayer || []

  // obfuscate #access_token from URL
  locationStr = locationStr.replace(
    /access_token=[^&]+/,
    "access_token=REDACTED"
  )

  window.dataLayer.push({
    event: "pageView",
    title,
    location: locationStr,
  })

  debug && console.log(`Logged pageview "${title}" at ${locationStr}`)
}

// Tracks a custom event. Called manually from components (e.g. during a form submission)
export const trackEvent = ({ category, action, name, value }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "customEvent",
    category,
    action,
    name,
    value,
  })
  debug && console.log(`Logged custom event "${name}"`)
}

// Display the Consent Manager manually
export const showConsentManager = () => {
  const cm = document.querySelector("div[id^=ppms_cm_consent_popup_]")
  if (cm) {
    cm.style.display = "block"
  }
  whenAvailable("ppms", () => window.ppms.cm.api("openConsentForm")) // wait for Consent Manager to be available (third party script)
}

// Basic setup for Piwik Pro tracking and Consent Manager
export const setupTracking = () => {
  window.showConsentManager = showConsentManager

  PiwikPro.initialize(
    process.env.GATSBY_PIWIK_CONTAINER_ID,
    process.env.GATSBY_PIWIK_CONTAINER_URL
  )

  setEnvironment()
  saveTrackingIds()
  saveUtmParams()
}

// Runs the page tracking. Called manually from gatsby-browser.js on every route change.
export const runPageTracking = (locationStr) => {
  // Hide Consent Manager on pages that need to be accessible for GDPR compliance
  if (locationStr.match(excludeConsentPaths)) {
    disableConsentManager()
  } else {
    enableConsentManager()
  }

  const runTracking = () => {
    trackPageView(document.title.replace(/( \|)(?!.* \|).*/, ""), locationStr) // filter out " | Site Name" at end of title
  }

  // Minimum delay for reactHelmet's requestAnimationFrame
  const delay = Math.max(32, 0)

  setTimeout(runTracking, delay)
}
