import React from "react"
import { Link } from "gatsby"
import { isMatch } from "picomatch"
import { isUrlPattern, sanitizeInput } from "./validation"

// Shorten text
export const truncate = (str, maxlength) => {
  return str.length > maxlength ? str.slice(0, maxlength - 1) + "…" : str
}

// TODO: delete link handling if gatsby-transformer-remark, gatsby-plugin-catch-links and gatsby-remark-external-links cover all use-cases

// Detect if we want to preserve this link from Gatsby Link
// Include file directories
export const isProtectedLink = (link) =>
  link.match(/^((mailto|javascript):|\/(documents|download|content))/) !== null

// Detect links that should be handled by Gatsby Link
export const isInternalLink = (link) => link.match(/^\//) !== null

export const handleLinks = (props) => {
  if (isProtectedLink(props.href)) {
    return <a href={props.href}>{props.children}</a>
  }

  if (isInternalLink(props.href)) {
    return <Link to={props.href}>{props.children}</Link>
  }

  // If neither, return an external link with secure attributes
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
}

export const getParentPath = (path) => {
  const splitPath = path.split("/")

  // if path includes a language (de, en, fr, it, es), include it in the parent path
  if (splitPath[1].match(/(de|en|fr|it|es)/))
    return `/${splitPath[1]}/${splitPath[2]}/`
  // otherwise just return the first directory, e.g. /blog/
  return `/${splitPath[1]}/`
}

export const isBrowser = () => typeof window !== "undefined"

const isMobile = () => {
  const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0
  const isSmallScreen = window.innerWidth <= 768
  return isTouchDevice && isSmallScreen
}

const isMobileUserAgent = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  return /android|iphone|ipad|iPod|blackberry|iemobile|opera mini/i.test(
    userAgent.toLowerCase()
  )
}

export const isMobileDevice = () => {
  return isMobile() || isMobileUserAgent()
}

export const scrollToRef = (ref) => {
  const elementRect = ref.current.getBoundingClientRect().top
  const headerHeight = document.querySelector("#headerHeight").offsetHeight

  if (ref.current.getBoundingClientRect().top < 0) {
    const bodyRect = document.body.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - headerHeight - 1
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
  }
}

export const isClient = () =>
  typeof window !== "undefined" && typeof window.document !== "undefined"

export const daysFromNow = (days) =>
  new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000)

export const toTitleCase = (str) =>
  str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(" ")

export const fixCommaValue = (value) => String(value).replace(".", ",")

export const makeFastlink = (url) =>
  url.replace("https://www.solarisgroup.com", "")

export const slugify = (text, separator, preserveDot = false) => {
  const nonWordCharacters = preserveDot === true ? /[^\w.-]+/g : /[^\w-]+/g
  text = text.toString().toLowerCase().trim()

  const sets = [
    { to: "a", from: "[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]" },
    { to: "ae", from: "[Ä]" },
    { to: "c", from: "[ÇĆĈČ]" },
    { to: "d", from: "[ÐĎĐÞ]" },
    { to: "e", from: "[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]" },
    { to: "g", from: "[ĜĞĢǴ]" },
    { to: "h", from: "[ĤḦ]" },
    { to: "i", from: "[ÌÍÎÏĨĪĮİỈỊ]" },
    { to: "j", from: "[Ĵ]" },
    { to: "ij", from: "[Ĳ]" },
    { to: "k", from: "[Ķ]" },
    { to: "l", from: "[ĹĻĽŁ]" },
    { to: "m", from: "[Ḿ]" },
    { to: "n", from: "[ÑŃŅŇ]" },
    { to: "o", from: "[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]" },
    { to: "oe", from: "[ŒÖ]" },
    { to: "p", from: "[ṕ]" },
    { to: "r", from: "[ŔŖŘ]" },
    { to: "s", from: "[ŚŜŞŠ]" },
    { to: "ss", from: "[ß]" },
    { to: "t", from: "[ŢŤ]" },
    { to: "u", from: "[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]" },
    { to: "ue", from: "[Ü]" },
    { to: "w", from: "[ẂŴẀẄ]" },
    { to: "x", from: "[ẍ]" },
    { to: "y", from: "[ÝŶŸỲỴỶỸ]" },
    { to: "z", from: "[ŹŻŽ]" },
    { to: "-", from: "[·/_,:;']" },
  ]

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, "gi"), set.to)
  })

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(nonWordCharacters, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text

  if (typeof separator !== "undefined" && separator !== "-") {
    text = text.replace(/-/g, separator)
  }

  return text
}

// Get filename from path
export const getFilename = (path) => {
  const regex = /[^/]+$/
  const match = path.match(regex)
  return match ? match[0] : null
}

// Get URL parameters
export const getUrlParam = (parameter) => {
  const match = RegExp("[?&]" + parameter + "=([^&]*)").exec(
    window.location.search
  )
  return match && decodeURIComponent(match[1].replace(/\+/g, " "))
}

// Check script availability before executing
export const whenAvailable = (name, callback) => {
  const intervalId = window.setInterval(function () {
    if (window[name]) {
      window.clearInterval(intervalId)
      callback(window[name])
    }
  }, 100)
}

// Keep checking for condition until available, then execute callback
export const whenCondition = (condition, callback, fallback, maxRuns) => {
  let runs = 0

  const intervalId = window.setInterval(function () {
    // console.log(`interval run ${runs} times`)

    const result = condition()
    if (result) {
      // console.log(`condition is ${result}`)
      window.clearInterval(intervalId)
      callback(result)
    }
    if (runs >= maxRuns) {
      window.clearInterval(intervalId)
      fallback()
    }
    runs++
  }, 100)
}

export const loadScript = (scriptUrl) => {
  const script = document.createElement("script")
  script.src = scriptUrl
  document.body.appendChild(script)

  return new Promise((res, rej) => {
    script.onload = function () {
      res()
    }
    script.onerror = function () {
      rej()
    }
  })
}

export const removeHash = () => {
  window.history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  )
}

export const getHighlightedText = (text, highlight) => {
  // Split on highlight term and include term into parts, ignore case

  const parts = highlight
    ? text?.split(new RegExp(`(${highlight})(?![^<]*>)`, "gi"))
    : null
  const result = `${
    highlight
      ? parts
          ?.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase()
              ? `<mark class="highlightText">${part}</mark>`
              : part
          )
          .join("")
      : text
  }`

  return result
}

export const isMatchedIgnoredPaths = (ignoredPaths, pathname) =>
  isMatch(pathname, ignoredPaths)

export const getOptimizedRssDotComThumbnail = (url, width) => {
  let finalUrl = url.replace("media.rss.com", "images.rss.com")
  const urlParts = finalUrl.split("/")
  urlParts.splice(-1, 0, width.toString())
  finalUrl = urlParts.join("/")
  return finalUrl
}

export { isUrlPattern, sanitizeInput }
